/**
 * 取得日線的最低價
 *
 * @param this
 * @param daysBack 期別
 * @param sessionType 交易時段
 * @returns number[]
 */
export function dailyLow(daysBack, sessionType) {
    const low = this.ohlc.lowArray;
    low.get(4096);
    const dayLowArray = this._context.new_var();
    dayLowArray.set(this.intradayLowest(low, sessionType));
    const prevLow = this._context.new_var();
    dayLowArray.get(4096);
    prevLow.get(4096);
    if (this.isSessionLastBar(sessionType)) {
        prevLow.set(dayLowArray.get(0));
    }
    const dailyLowArray = [];
    for (let i = 0; i < daysBack; i++) {
        if (i === 0) {
            dailyLowArray.push(dayLowArray.get(i));
        }
        else {
            dailyLowArray.push(prevLow.get(i));
        }
    }
    return dailyLowArray;
}
