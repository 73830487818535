/**
 * 計算序列資料的最大值
 *
 * @param this
 * @param series 數列
 * @param length 期數
 * @param type 1: Max -1: Min
 * @returns Number
 */
export function extremeValue(series, length, type) {
    const result = this._context.new_var();
    const currentBar = series.hist_pos;
    let extreme = 0;
    let exLength = 0;
    let extremeBarNum = 0;
    if (1 > length) {
        return 0;
    }
    if (length !== exLength || currentBar === 0 || extremeBarNum >= length - 1) {
        extreme = series.get(0);
        extremeBarNum = 0;
        for (let i = 1; i <= length - 1; i++) {
            if (type * series.get(i) > type * extreme) {
                extreme = series.get(i);
                extremeBarNum = i;
            }
        }
    }
    else {
        if (type * series.get(0) >= type * extreme) {
            extreme = series.get(0);
            extremeBarNum = 0;
        }
        else {
            extremeBarNum = extremeBarNum + 1;
        }
    }
    exLength = length;
    return extreme;
}
