/**
 * 計算數列的母體標準差
 *
 * @param this
 * @param series 數列
 * @param length 期數
 * @returns number
 */
export function standardDev(series, length) {
    series.get(length);
    const variance = this.variance(series, length);
    return variance > 0 ? Math.sqrt(variance) : 0;
}
