/**
 * 取得日線的開盤價
 *
 * @param this
 * @param daysBack 期別
 * @param sessionType 交易時段
 * @returns Number[]
 */
export function dailyOpen(daysBack, sessionType) {
    const open = this.ohlc.openArray;
    open.get(1024);
    const dayOpen = this._context.new_var();
    const prevOpen = this._context.new_var();
    dayOpen.get(1024);
    prevOpen.get(1024);
    if (this.isSessionFirstBar(sessionType)) {
        dayOpen.set(open.get(0));
    }
    if (this.isSessionLastBar(sessionType)) {
        prevOpen.set(dayOpen.get(0));
    }
    const dailyOpenArray = [];
    for (let i = 0; i < daysBack; i++) {
        if (i === 0) {
            dailyOpenArray.push(dayOpen.get(i));
        }
        else {
            dailyOpenArray.push(prevOpen.get(i));
        }
    }
    return dailyOpenArray;
}
