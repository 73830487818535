/**
 * Typical price
 * @param this
 * @returns number
 */
export function typicalPrice() {
    const high = this.ohlc.highArray;
    const low = this.ohlc.lowArray;
    const close = this.ohlc.closeArray;
    return (high.get(0) + low.get(0) + close.get(0)) / 3;
}
