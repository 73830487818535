/**
 * Keltner Channel
 *
 * @param this
 * @param length 期數
 * @param atrs ATR倍數
 * @returns PineJS.NewVar<number>[上通道, 下通道線, SMA]
 */
export function keltnerChannel(length, atrs) {
    const sma = this.average(this.ohlc.closeArray, length);
    const keltnerHigh = sma + atrs * this.atr(length);
    const keltnerLow = sma - atrs * this.atr(length);
    return { top: keltnerHigh, bottom: keltnerLow, mid: sma };
}
