import { isSessionFirstBarV2 } from './isSessionFirstBar';
/**
 * 計算日內特定數列最小值
 *
 * @param this
 * @param series 數列
 * @param sessionType 交易時段
 * @returns Number
 */
export function intradayLowest(series, sessionType) {
    return this.lowest(series, this.intradayCurrentBar(sessionType));
}
export function intradayLowestV2(ll, tt, sessionType) {
    for (let i = 0; i < Math.min(ll.mindepth, tt.mindepth); i++) {
        if (isSessionFirstBarV2(tt.get(i), tt.get(i + 1), this._context.symbol.period, sessionType)) {
            return this.PineJS.Std.lowest(ll, i + 1, this._context);
        }
    }
    return NaN;
}
