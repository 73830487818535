/**
 * 計算當日均價
 *
 * @param this
 * @param sessionType 交易時段
 * @returns Number
 */
export function dayAvgPrice(sessionType) {
    return this.intradayAverage(this.ohlc.closeArray, sessionType);
}
