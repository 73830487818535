export function dayTradeAvgPrice() {
    const time = this.PineJS.Std.time(this._context);
    const time_hours = new Date(time).getHours();
    const time_minutes = new Date(time).getMinutes();
    const close = this.PineJS.Std.hlc3(this._context);
    const ticks = this.PineJS.Std.volume(this._context);
    const total_closeticks = this._context.new_var();
    const total_ticks = this._context.new_var();
    const avg_price = total_closeticks / total_ticks;
    if (time_hours === 9 && time_minutes === 0) {
        total_closeticks.set(close * ticks);
        total_ticks.set(ticks);
    }
    else {
        total_closeticks.set(total_closeticks + close * ticks);
        total_ticks.set(total_ticks + ticks);
    }
    return avg_price;
}
