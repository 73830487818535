/**
 * 計算number array加總
 * @param this
 * @param listToSum number array
 * @returns number
 */
export function summationList(listToSum) {
    let sumValue = 0;
    for (let i = 0; i < listToSum.length; i++) {
        sumValue += listToSum[i];
    }
    return sumValue;
}
