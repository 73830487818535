/**
 * 計算序列資料的移動平均
 *
 * @param this
 * @param series 數列
 * @param length 期數
 * @returns number
 */
export function average(series, length) {
    return this.summation(series, length) / length;
}
