import { toInteger } from 'lodash';
/**
 * Hull Moving Average
 * @param this
 * @param series 序列
 * @param length 期數
 * @returns
 */
export function hma(series, length) {
    //series.get(512)
    const wma1 = this.wma(series, toInteger(length / 2));
    const wma2 = this.wma(series, length);
    const ma = this._context.new_var(2 * wma1 - wma2);
    return this.wma(ma, toInteger(this.PineJS.Std.sqrt(length)));
}
