/**
 * 收盤價與均線的乖離程度
 * @param this
 * @param length 期數
 * @returns number
 */
export function bias(length) {
    const close = this.ohlc.closeArray;
    close.get(100);
    const sma = this.average(close, length);
    const biasValue = ((close.get(0) - sma) / sma) * 100;
    return biasValue;
}
