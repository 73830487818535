export function logPositionInfo(positionStatus, flagStatus, entrySoundStatus, exitSoundStatus) {
    const itime = this.PineJS.Std.time(this._context);
    const displayTime = new Date(itime).toLocaleTimeString('en-US');
    return ('時間:' +
        displayTime +
        '\n' +
        '部位:' +
        positionStatus.toString() +
        ' / ' +
        '停利階段:' +
        flagStatus.toString() +
        ' / ' +
        '進場聲音狀態:' +
        entrySoundStatus.toString() +
        ' / ' +
        '出場聲音狀態:' +
        exitSoundStatus.toString() +
        '\n');
}
