/**
 * 計算平均真實區間
 * @param this
 * @param length 期數
 * @returns number
 */
export function atr(length) {
    const trueRange = this._context.new_var(this.trueRange());
    return this.average(trueRange, length);
}
