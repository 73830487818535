/**
 * 取得日線的最高價
 *
 * @param this
 * @param daysBack 期別
 * @param sessionType 交易時段
 * @returns number[]
 */
export function dailyHigh(daysBack, sessionType) {
    const high = this.ohlc.highArray;
    high.get(4096);
    const dayHighArray = this._context.new_var();
    dayHighArray.set(this.intradayHighest(high, sessionType));
    const prevHigh = this._context.new_var();
    dayHighArray.get(4096);
    prevHigh.get(4096);
    if (this.isSessionLastBar(sessionType)) {
        prevHigh.set(dayHighArray.get(0));
    }
    const dailyHighArray = [];
    for (let i = 0; i < daysBack; i++) {
        if (i === 0) {
            dailyHighArray.push(dayHighArray.get(i));
        }
        else {
            dailyHighArray.push(prevHigh.get(i));
        }
    }
    return dailyHighArray;
}
