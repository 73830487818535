/**
 * Volume Weighted Moving Average
 * @param this
 * @param length 期數
 * @returns
 */
export function vwma(length) {
    const avgPrice = this.avgPrice();
    const volume = this._context.new_var(this.PineJS.Std.volume(this._context));
    const tradeValue = this._context.new_var(avgPrice * volume.get(0));
    const tradeValueSum = this.summation(tradeValue, length);
    const volumeSum = this.summation(volume, length);
    return tradeValueSum / volumeSum;
}
