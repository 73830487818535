export function analyzer(length1, length2, length3) {
    const len = length1;
    const in1 = length2;
    const in2 = length3;
    const c = this.PineJS.Std.close(this._context);
    const h = this.PineJS.Std.high(this._context);
    const l = this.PineJS.Std.low(this._context);
    const time = this.PineJS.Std.time(this._context);
    const nc = this._context.new_var(c);
    const nh = this._context.new_var(h);
    const nl = this._context.new_var(l);
    const rsv = this.PineJS.Std.stoch(nc, nh, nl, len, this._context);
    const nrsv = this._context.new_var(rsv);
    const k = this.PineJS.Std.sma(nrsv, in1, this._context);
    const f = this._context.new_var(k);
    const d = this.PineJS.Std.sma(f, in2, this._context);
    // console.log(
    //   dayAPI(time).format('MM/DD'),
    //   'KD',
    //   rsv.toFixed(2),
    //   'k:',
    //   k.toFixed(2),
    //   'd:',
    //   d.toFixed(2),
    // )
    return [k, d];
}
