/**
 * 當根K棒的真實區間
 * @param this
 * @returns number
 */
export function trueRange() {
    const close = this.ohlc.closeArray;
    const high = this.ohlc.highArray;
    const low = this.ohlc.lowArray;
    let trueHigh;
    let trueLow;
    if (close.get(1) > high.get(0)) {
        trueHigh = close.get(1);
    }
    else {
        trueHigh = high.get(0);
    }
    if (close.get(1) < low.get(0)) {
        trueLow = close.get(1);
    }
    else {
        trueLow = low.get(0);
    }
    return trueHigh - trueLow;
}
