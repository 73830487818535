/**
 * Pivot Point
 *
 * @param this
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[壓力3, 壓力2, 壓力1, PivotPoint, 支撐1, 支撐2, 支撐3]
 */
export function pivotPoint(sessionType) {
    const prevHigh = this.dailyHigh(5, sessionType)[1];
    const prevLow = this.dailyLow(5, sessionType)[1];
    const prevClose = this.dailyClose(5, sessionType)[1];
    const pivotPointValue = (prevHigh + prevLow + prevClose) / 3;
    const r3 = prevHigh + 2 * (pivotPointValue - prevLow);
    const r2 = pivotPointValue + prevHigh - prevLow;
    const r1 = 2 * pivotPointValue - prevLow;
    const s1 = 2 * pivotPointValue - prevHigh;
    const s2 = pivotPointValue - prevHigh + prevLow;
    const s3 = prevLow - 2 * (prevHigh - pivotPointValue);
    return {
        pivot: pivotPointValue,
        resistant1: r1,
        resistant2: r2,
        resistant3: r3,
        support1: s1,
        support2: s2,
        support3: s3,
    };
}
