import { logPositionInfo } from '~/modules/SDK/indicator/contextUtils/getPosistionInfo';
import { isSettlementDay } from '~/modules/SDK/indicator/contextUtils/isSettlementDay';
import { isSettlementDayDailyKbar } from './contextUtils/isSettlementDayDailyKbar';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { useIndicatorStore2 } from '~/store/useIndicatorStore2';
import { __TEST__ } from '~/utils/__TEST__';
import { isBarChanging } from '~/modules/SDK/indicator/contextUtils/isBarChanging';
import { ladderChannl } from './contextUtils/ladderChannel';
import { avgTrueRange } from './contextUtils/avgTrueRange';
import { dmi } from './contextUtils/dmi';
import { sarLongStopLoss } from './contextUtils/sarLongStopLoss';
import { sarShortStopLoss } from './contextUtils/sarShortStopLoss';
import { dayTradeAvgPrice } from './contextUtils/dayTradeAvgPrice';
import { ok1788customized1 } from '../indicator/contextUtils/ok1788customized1';
import { ok1788customized2 } from '../indicator/contextUtils/ok1788customized2';
import { ok1788customized3 } from '../indicator/contextUtils/ok1788customized3';
import { analyzer } from './contextUtils/customized/analyzer';
import { ladderMidPrice } from './contextUtils/customized/ladderMidPrice';
import { highest } from './contextUtils/functions/highest';
import { lowest } from './contextUtils/functions/lowest';
import { donchianChannel } from './contextUtils/indicators/donchianChannel';
import { percentR } from './contextUtils/indicators/percentR';
import { stochastic } from './contextUtils/indicators/stochastic';
import { summation } from './contextUtils/functions/summation';
import { average } from './contextUtils/functions/average';
import { maxList } from './contextUtils/functions/maxList';
import { minList } from './contextUtils/functions/minList';
import { rsi } from './contextUtils/indicators/rsi';
import { variance } from './contextUtils/functions/variance';
import { standardDev } from './contextUtils/functions/standardDev';
import { bollingerBand } from './contextUtils/indicators/bollingerBand';
import { trueRange } from './contextUtils/functions/trueRange';
import { atr } from './contextUtils/indicators/atr';
import { keltnerChannel } from './contextUtils/indicators/keltnerChannel';
import { typicalPrice } from './contextUtils/functions/typicalPrice';
import { cci } from './contextUtils/indicators/cci';
import { momentum } from './contextUtils/indicators/momentum';
import { bias } from './contextUtils/indicators/bias';
import { exponentialAverage } from './contextUtils/functions/exponentialAverage';
import { weightedClose } from './contextUtils/functions/weightedClose';
import { macd } from './contextUtils/indicators/macd';
import { intradayCurrentBar } from './contextUtils/functions/intradayCurrentBar';
import { intradayHighest, intradayHighestV2 } from './contextUtils/functions/intradayHighest';
import { intradayLowest, intradayLowestV2 } from './contextUtils/functions/intradayLowest';
import { intradaySummation } from './contextUtils/functions/intradaySummation';
import { intradayAverage } from './contextUtils/functions/intradayAverage';
import { avgPrice } from './contextUtils/functions/avgPrice';
import { dayAvgPrice } from './contextUtils/indicators/dayAvgPrice';
import { vwap } from './contextUtils/indicators/vwap';
import { isSessionFirstBarV3, isSessionFirstBar, isSessionFirstBarV2, } from './contextUtils/functions/isSessionFirstBar';
import { strongWeakGate } from './contextUtils/functions/strongWeakGate';
import { vwma } from './contextUtils/functions/vwma';
import { dailyOpen } from './contextUtils/functions/dailyOpen';
import { dailyHigh } from './contextUtils/functions/dailyHigh';
import { dailyLow } from './contextUtils/functions/dailyLow';
import { dailyClose } from './contextUtils/functions/dailyClose';
import { threeGatePrice } from './contextUtils/indicators/threeGatePrice';
import { cdp } from './contextUtils/indicators/cdp';
import { pivotPoint } from './contextUtils/indicators/pivotPoint';
import { summationList } from './contextUtils/functions/summationList';
import { averageList } from './contextUtils/functions/averageList';
import { greatestSwing } from './contextUtils/functions/grestestSwing';
import { greatestSwingChannel } from './contextUtils/indicators/greatestSwingChannel';
import { openRange } from './contextUtils/functions/openRange';
import { orbChannel } from './contextUtils/indicators/orbChannel';
import { dualThrustRange } from './contextUtils/functions/dualThrustRange';
import { dualThrust } from './contextUtils/indicators/dualThrust';
import { hma } from './contextUtils/indicators/hma';
import { wma } from './contextUtils/indicators/wma';
import { extremeValue } from './contextUtils/functions/extremeValue';
import { cloneDeep, defaultsDeep } from 'lodash';
import { isIntradayLastBar } from './contextUtils/functions/isIntradayLastBar';
import { isIntradayFirstBar } from './contextUtils/functions/isIntradayFirstBar';
import { isSessionLastBar, isSessionLastBarV2 } from './contextUtils/functions/isSessionLastBar';
import { adaptiveMovingAverage } from './contextUtils/indicators/adaptiveMovingAverage';
/**
 * 建立強型別的客製化指標
 *
 * ### 生命週期
 *
 * - 各自 indicator 各自走自己的生命週期
 *
 * 1. 指標被 Chart Preload 時，執行一次 constructor() 以及 init() 隨後不執行
 * 2. 指標每次 update（例如切換指標進來、或是 K 棒跳動）時，執行一次 main()
 * 3. 指標初始所有顯示 K 棒，每根都會執行一次 main()
 */
export function createIndicator(config) {
    const indicatorFN = pineJS => {
        return {
            name: config.displayName,
            metainfo: {
                visible: true,
                id: `futures--${config.id}@tv-basicstudies-1`,
                name: config.displayName,
                description: `futures--${config.id}`,
                shortDescription: config.displayName,
                scriptIdPart: '',
                _metainfoVersion: 40,
                ...config.metainfo,
            },
            constructor() {
                this.useMeStore = useMeStore;
                this.PineJS = pineJS;
                this.init = (context, inputCallback) => {
                    this._context = context;
                    this._input = inputCallback;
                    // ！！！！！放這裡會 not enough depth: 17919
                    // sinopac2MAbref.bstate = this._context.new_var(0)
                    // context aware stateful helper functions
                    this.isBarChanging = isBarChanging.bind(this);
                    this.isSettlementDay = isSettlementDay.bind(this);
                    this.isSettlementDayDailyKbar = isSettlementDayDailyKbar.bind(this);
                    this.logPositionInfo = logPositionInfo.bind(this);
                    this.ladderChannl = ladderChannl.bind(this);
                    this.avgTrueRange = avgTrueRange.bind(this);
                    this.dmi = dmi.bind(this);
                    this.sarLongStopLoss = sarLongStopLoss.bind(this);
                    this.sarShortStopLoss = sarShortStopLoss.bind(this);
                    this.dayTradeAvgPrice = dayTradeAvgPrice.bind(this);
                    this.ok1788customized1 = ok1788customized1.bind(this);
                    this.ok1788customized2 = ok1788customized2.bind(this);
                    this.ok1788customized3 = ok1788customized3.bind(this);
                    this.analyzer = analyzer.bind(this);
                    this.ladderMidPrice = ladderMidPrice.bind(this);
                    // ---------------------------------------------------- New Features ----------------------------------------------------------------
                    this.isSessionFirstBar = isSessionFirstBar.bind(this);
                    this.isSessionFirstBarV2 = isSessionFirstBarV2.bind(this);
                    this.isSessionFirstBarV3 = isSessionFirstBarV3.bind(this);
                    this.isSessionLastBar = isSessionLastBar.bind(this);
                    this.isSessionLastBarV2 = isSessionLastBarV2.bind(this);
                    this.isIntradayFirstBar = isIntradayFirstBar.bind(this);
                    this.isIntradayLastBar = isIntradayLastBar.bind(this);
                    this.highest = highest.bind(this);
                    this.lowest = lowest.bind(this);
                    this.donchianChannel = donchianChannel.bind(this);
                    this.percentR = percentR.bind(this);
                    this.stochastic = stochastic.bind(this);
                    this.summation = summation.bind(this);
                    this.average = average.bind(this);
                    this.maxList = maxList.bind(this);
                    this.minList = minList.bind(this);
                    this.rsi = rsi.bind(this);
                    this.variance = variance.bind(this);
                    this.standardDev = standardDev.bind(this);
                    this.bollingerBand = bollingerBand.bind(this);
                    this.trueRange = trueRange.bind(this);
                    this.atr = atr.bind(this);
                    this.keltnerChannel = keltnerChannel.bind(this);
                    this.typicalPrice = typicalPrice.bind(this);
                    this.cci = cci.bind(this);
                    this.momentum = momentum.bind(this);
                    this.bias = bias.bind(this);
                    this.exponentialAverage = exponentialAverage.bind(this);
                    this.weightedClose = weightedClose.bind(this);
                    this.macd = macd.bind(this);
                    this.intradayCurrentBar = intradayCurrentBar.bind(this);
                    this.intradayHighest = intradayHighest.bind(this);
                    this.intradayLowest = intradayLowest.bind(this);
                    this.intradayHighestV2 = intradayHighestV2.bind(this);
                    this.intradayLowestV2 = intradayLowestV2.bind(this);
                    this.intradaySummation = intradaySummation.bind(this);
                    this.intradayAverage = intradayAverage.bind(this);
                    this.avgPrice = avgPrice.bind(this);
                    this.dayAvgPrice = dayAvgPrice.bind(this);
                    this.vwap = vwap.bind(this);
                    this.strongWeakGate = strongWeakGate.bind(this);
                    this.vwma = vwma.bind(this);
                    this.dailyOpen = dailyOpen.bind(this);
                    this.dailyHigh = dailyHigh.bind(this);
                    this.dailyLow = dailyLow.bind(this);
                    this.dailyClose = dailyClose.bind(this);
                    this.threeGatePrice = threeGatePrice.bind(this);
                    this.cdp = cdp.bind(this);
                    this.pivotPoint = pivotPoint.bind(this);
                    this.summationList = summationList.bind(this);
                    this.averageList = averageList.bind(this);
                    this.greatestSwing = greatestSwing.bind(this);
                    this.greatestSwingChannel = greatestSwingChannel.bind(this);
                    this.openRange = openRange.bind(this);
                    this.orbChannel = orbChannel.bind(this);
                    this.dualThrustRange = dualThrustRange.bind(this);
                    this.dualThrust = dualThrust.bind(this);
                    this.hma = hma;
                    this.wma = wma;
                    this.extremeValue = extremeValue.bind(this);
                    this.adaptiveMovingAverage = adaptiveMovingAverage.bind(this);
                    config.constructorScope.init.call(this, context, inputCallback);
                };
                this.main = (context, inputCallback) => {
                    this._context = context;
                    this._input = inputCallback;
                    // 以下為自定義的各種共用函式庫
                    this.ohlc = {
                        get open() {
                            return pineJS.Std.open(context);
                        },
                        get high() {
                            return pineJS.Std.high(context);
                        },
                        get low() {
                            return pineJS.Std.low(context);
                        },
                        get close() {
                            return pineJS.Std.close(context);
                        },
                        openArray: this._context.new_var(),
                        highArray: this._context.new_var(),
                        lowArray: this._context.new_var(),
                        closeArray: this._context.new_var(),
                    };
                    this.ohlc.openArray = this._context.new_var(this.ohlc.open);
                    this.ohlc.highArray = this._context.new_var(this.ohlc.high);
                    this.ohlc.lowArray = this._context.new_var(this.ohlc.low);
                    this.ohlc.closeArray = this._context.new_var(this.ohlc.close);
                    this.timeArray = this._context.new_var(pineJS.Std.time(context));
                    this.bs = {
                        entryAt: this._context.new_var(),
                        entryPriceClose: this._context.new_var(),
                        entryPriceHigh: this._context.new_var(),
                        entryPriceLow: this._context.new_var(),
                        entryPriceOpen: this._context.new_var(),
                        position: this._context.new_var(),
                        buy() {
                            this.position.set(1);
                            this.entryPriceOpen = pineJS.Std.open(context);
                            this.entryPriceHigh = pineJS.Std.high(context);
                            this.entryPriceLow = pineJS.Std.low(context);
                            this.entryPriceClose = pineJS.Std.close(context);
                            this.entryAt = pineJS.Std.time(context);
                            useIndicatorStore2.getState().buy();
                        },
                        sell() {
                            this.position.set(-1);
                            this.entryPriceOpen = pineJS.Std.open(context);
                            this.entryPriceHigh = pineJS.Std.high(context);
                            this.entryPriceLow = pineJS.Std.low(context);
                            this.entryPriceClose = pineJS.Std.close(context);
                            this.entryAt = pineJS.Std.time(context);
                            useIndicatorStore2.getState().sell();
                        },
                        closeAll() {
                            this.position.set(0);
                            this.entryPriceOpen = pineJS.Std.open(context);
                            this.entryPriceHigh = pineJS.Std.high(context);
                            this.entryPriceLow = pineJS.Std.low(context);
                            this.entryPriceClose = pineJS.Std.close(context);
                            this.entryAt = pineJS.Std.time(context);
                            useIndicatorStore2.getState().closeAll();
                        },
                        get isPositionBuying() {
                            this.position.get(0);
                            return this.position.get(0) === 1;
                        },
                        get isPositionSelling() {
                            this.position.get(0);
                            return this.position.get(0) === -1;
                        },
                        get isPositionJustBuy() {
                            this.position.get(0);
                            this.position.get(1);
                            return this.position.get(0) === 1 && this.position.get(1) !== 1;
                        },
                        get isPositionJustSell() {
                            this.position.get(0);
                            this.position.get(1);
                            return this.position.get(0) === -1 && this.position.get(1) !== -1;
                        },
                    };
                    // usePinejsStore.getState().initPosition.call(this)
                    // usePinejsStore.getState().positions?.get(1) // 魔幻必 call 否則拿不到值
                    const result = config.constructorScope.main.call(this, context, inputCallback);
                    return result;
                };
            },
        };
    };
    indicatorFN.enabledOn =
        // filter 優先，作為一個包裝層，將覆蓋 enabledOn 功能
        config.filter
            ? (symbol, data, channel, interval, instrumentSymbol) => {
                return config.filter?.({ instrument: instrumentSymbol, interval, symbol }) ?? true;
            }
            : // 其次，才直接使用 enabledOn 功能
                config.enabledOn
                    ? config.enabledOn
                    : // 如果連 enabledOn 也沒給，那就填充一個 `true`
                        () => {
                            return true;
                        };
    indicatorFN.id = `futures--${config.id}`;
    indicatorFN.displayName = config.displayName;
    indicatorFN.config = cloneDeep(config);
    indicatorFN.duplicate = $config => createIndicator(defaultsDeep($config, indicatorFN.config));
    return indicatorFN;
}
/* istanbul ignore next */
if (__TEST__) {
    const myIndicator = createIndicator({
        id: 'TYPING-TESTING',
        displayName: '型別單元測試',
        metainfo: {},
        constructorScope: {
            // 這裡預期可以自由新增 function；而不需改動到 createIndicator（不用改到底層）
            user_ma(foo, bar) {
                //
            },
            // 這裡預期可以自由新增 function；而不需改動到 createIndicator（不用改到底層）
            main1(foo, bar) {
                //
            },
            init() {
                //
            },
            main(context, inputCallback) {
                return [];
            },
        },
    });
    const myIndicator2 = createIndicator({
        id: 'TYPING-TESTING',
        displayName: '型別單元測試',
        metainfo: {},
        /** @ts-expect-error WHY：若缺 init() 這裡預期 typing 報錯 */
        constructorScope: {
            main(context, inputCallback) {
                return [];
            },
        },
    });
    const myIndicator3 = createIndicator({
        id: 'TYPING-TESTING',
        displayName: '型別單元測試',
        metainfo: {},
        /** @ts-expect-error WHY：若缺 main() 這裡預期 typing 報錯 */
        constructorScope: {
            init() {
                //
            },
        },
    });
}
