/**
 * 計算日內特定數列平均
 * @param this
 * @param series 數列
 * @param sessionType 交易時段
 * @returns number
 */
export function intradayAverage(series, sessionType) {
    // const intradayCurrentBar: number = this.intradayCurrentBar(sessionType)
    // return this.average(series, intradayCurrentBar)
    return this.intradaySummation(series, sessionType) / this.intradayCurrentBar(sessionType);
}
