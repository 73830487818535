/**
 * Weighted Moving Average
 * @param this
 * @param series 序列
 * @param length 期數
 * @returns
 */
export function wma(series, length) {
    let factor = 0;
    if (factor == 0) {
        factor = 0.5 * length * (length + 1);
    }
    let wmaValue = 0;
    if (series.hist_pos < length) {
        wmaValue = series.get(0);
    }
    else {
        wmaValue = length * series.get(0);
        for (let i = 1; i <= length; i++) {
            wmaValue += series.get(i) * (length - i);
        }
        wmaValue = wmaValue / factor;
    }
    return wmaValue;
}
