import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
/**
 * Determine if the current bar is the first one of the intraday.
 *
 * @param this
 * @returns
 */
export function isIntradayFirstBar() {
    return this.isSessionFirstBarV3(fr_instrument.getSessions(this._context.symbol.ticker).at(0));
}
