export function ladderMidPrice(length) {
    const o = this.PineJS.Std.low(this._context);
    const r = this._context.new_var(o);
    const s = this.PineJS.Std.lowest(r, length, this._context);
    const a = this.PineJS.Std.high(this._context);
    const l = this._context.new_var(a);
    const c = this.PineJS.Std.highest(l, length, this._context);
    const result = this.PineJS.Std.avg(c, s);
    const result_array = this._context.new_var(result);
    result_array.get(length + 1);
    return result_array;
}
