/**
 * 計算Array中的最大值
 * @param this
 * @param numberArray
 * @returns
 */
export function maxList(numberArray) {
    let maxValue = 0;
    numberArray.forEach(num => {
        if (maxValue === 0) {
            maxValue = num;
        }
        else if (num > maxValue) {
            maxValue = num;
        }
    });
    return maxValue;
}
