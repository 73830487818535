/**
 * Momentum index 計算數列指定期間的變動量
 * @param this
 * @param series 數列
 * @param length 期數
 * @returns number
 */
export function momentum(series, length) {
    return series.get(0) - series.get(length);
}
