/**
 * 計算當日成交量加權均價
 * @param this
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>
 */
export function vwap(sessionType) {
    const avgPrice = this.avgPrice();
    const volume = this._context.new_var(this.PineJS.Std.volume(this._context));
    // volume.get(512)
    volume.get(1);
    const tradeValue = this._context.new_var(avgPrice * volume.get(0));
    // tradeValue.get(512)
    tradeValue.get(1);
    const tradeValueSum = this.intradaySummation(tradeValue, sessionType);
    const volumeSum = this.intradaySummation(volume, sessionType);
    return tradeValueSum / volumeSum;
}
