/**
 * @param this
 * @param length 計算天數
 * @param fraction 通道大小乘數
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[GS-High, GS-Low]
 */
export function greatestSwingChannel(length, fraction, sessionType) {
    const dailyOpen = this.dailyOpen(length, sessionType);
    const greatestSwing = this.greatestSwing(length, sessionType);
    return {
        top: dailyOpen[0] + fraction * greatestSwing.longSwing,
        bottom: dailyOpen[0] - fraction * greatestSwing.shortSwing,
    };
}
