/**
 * BBand
 *
 * @param this
 * @param series 數列
 * @param length 期數
 * @param stds 標準差倍數
 * @returns number[上通道, 下通道, SMA]
 */
export function bollingerBand(series, length, stds) {
    series.get(length);
    const sma = this.average(series, length);
    const standardDev = this.standardDev(series, length);
    return { top: sma + stds * standardDev, bottom: sma - stds * standardDev, mid: sma };
}
