/**
 * 計算序列的母體變異數
 *
 * @param this
 * @param series 序列
 * @param length 期數
 * @returns number
 */
export function variance(series, length) {
    series.get(length);
    let varianceValue = 0;
    if (length > 0) {
        const avg = this.average(series, length);
        let sum = 0;
        for (let i = 0; i < length; i++) {
            sum += Math.pow(series.get(i) - avg, 2);
        }
        varianceValue = sum / length;
    }
    return varianceValue;
}
