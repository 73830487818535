/**
 * 計算序列資料的最小值
 * @param this
 * @param series 數列
 * @param length 期數
 * @returns number
 */
export function lowest(series, length) {
    return this.extremeValue(series, length, -1);
}
