/**
 * 取得當根K棒的均價 (Open + High + Low + Close) / 4
 * @param this
 * @returns number
 */
export function avgPrice() {
    const high = this.ohlc.highArray;
    const low = this.ohlc.lowArray;
    const close = this.ohlc.closeArray;
    const open = this.ohlc.openArray;
    return (high.get(0) + low.get(0) + open.get(0) + close.get(0)) * 0.25;
}
