//Average True Rage
export function avgTrueRange(length) {
    const close = this.PineJS.Std.close(this._context);
    const high = this.PineJS.Std.high(this._context);
    const low = this.PineJS.Std.low(this._context);
    const close_array = this._context.new_var(close);
    const trueRangeHigh = this._context.new_var();
    const trueRangeLow = this._context.new_var();
    if (close_array.get(1) > high) {
        trueRangeHigh.set(close_array.get(1));
    }
    else
        trueRangeHigh.set(high);
    if (close_array.get(1) < low) {
        trueRangeLow.set(close_array.get(1));
    }
    else
        trueRangeLow.set(low);
    const trueRange_ = this._context.new_var(trueRangeHigh.get(0) - trueRangeLow.get(0));
    const avgTrueRange_ = this.PineJS.Std.sma(trueRange_, length, this._context);
    const avgTrueRange_array = this._context.new_var(avgTrueRange_);
    return avgTrueRange_array;
}
