import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
/**
 * Determine if the current bar is the last one of the intraday.
 *
 * @param this
 * @returns
 */
export function isIntradayLastBar() {
    return this.isSessionLastBarV2(fr_instrument.getSessions(this._context.symbol.ticker).at(-1));
}
