/**
 * 威廉指標
 * @param this
 * @param length: number 期數
 * @returns number
 */
export function percentR(length) {
    const high = this.ohlc.highArray;
    const low = this.ohlc.lowArray;
    const close = this.ohlc.closeArray;
    const varA = this.highest(high, length);
    const varB = varA - this.lowest(low, length);
    let pctR;
    if (varB !== 0) {
        pctR = 100 - ((varA - close.get(0)) / varB) * 100;
    }
    else {
        pctR = 0;
    }
    return pctR;
}
