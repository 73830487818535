/**
 * ORB Channel
 *
 * @param this
 * @param length 計算ORB的天數
 * @param fraction 通道大小乘數
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[ORB High, ORB Low]
 */
export function orbChannel(length, fraction, sessionType) {
    const dailyOpen = this.dailyOpen(length, sessionType);
    const openRange = this.openRange(length, sessionType);
    return { top: dailyOpen[0] + fraction * openRange, bottom: dailyOpen[0] - fraction * openRange };
}
