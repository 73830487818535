/**
 * 計算Greatest Swing Value
 *
 * @param this
 * @param length 計算天數
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[]
 */
export function greatestSwing(length, sessionType) {
    const dailyOpen = this.dailyOpen(length + 2, sessionType);
    const dailyHigh = this.dailyHigh(length + 2, sessionType);
    const dailyLow = this.dailyLow(length + 2, sessionType);
    const longSwingList = [];
    const shortSwingList = [];
    for (let i = 0; i < length; i++) {
        longSwingList.push(dailyHigh[i + 1] - dailyOpen[i + 1]);
        shortSwingList.push(dailyOpen[i + 1] - dailyLow[i + 1]);
    }
    return {
        longSwing: this.averageList(longSwingList),
        shortSwing: this.averageList(shortSwingList),
    };
}
