/**
 * MACD
 *
 * @param this
 * @param fastLength 快速線（短期）的期數
 * @param slowLength 慢速線（長期）的期數
 * @param macdLength MACD使用之平滑期數
 * @returns number[dif, macd, osc]
 */
export function macd(fastLength, slowLength, macdLength) {
    const price = this._context.new_var(this.weightedClose());
    price.get(this.maxList([fastLength, slowLength, macdLength]) + 2);
    const fastMA = this.exponentialAverage(price, fastLength);
    const slowMA = this.exponentialAverage(price, slowLength);
    const difValue = fastMA - slowMA;
    const dif = this._context.new_var(difValue);
    dif.get(this.maxList([fastLength, slowLength, macdLength]) + 2);
    const macdValue = this.exponentialAverage(dif, macdLength);
    const oscValue = difValue - macdValue;
    return {
        osc: oscValue,
        dif: difValue,
        macd: macdValue,
    };
}
