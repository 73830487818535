/**
 * @param this
 * @param length: number 期數
 * @returns DonchianChannel
 */
export function donchianChannel(length) {
    const high = this.ohlc.highArray;
    const low = this.ohlc.lowArray;
    return {
        top: this.highest(this.ohlc.highArray, length),
        bottom: this.lowest(this.ohlc.lowArray, length),
    };
}
