/**
 * 計算日內特定數列加總
 *
 * @param this
 * @param series 數列
 * @param sessionType 交易時段
 * @returns number
 */
export function intradaySummation(series, sessionType) {
    const result = this._context.new_var();
    if (this.isSessionFirstBar(sessionType)) {
        result.set(series.get(0));
    }
    else {
        result.set(result.get(0) + series.get(0));
    }
    return result.get(0);
}
