/**
 * EMA
 *
 * @param this
 * @param series 數列
 * @param length 期數
 * @returns number
 */
export function exponentialAverage(series, length) {
    let factor = 0;
    const ema = this._context.new_var();
    series.get(length);
    ema.get(length);
    if (length + 1 === 0) {
        factor = 1;
    }
    else {
        factor = 2 / (length + 1);
    }
    if (!isNaN(ema.get(length))) {
        ema.set(ema.get(1) + factor * (series.get(0) - ema.get(1)));
    }
    else {
        ema.set(series.get(0));
    }
    return ema.get(0);
}
