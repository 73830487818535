/**
 *
 * @param this
 * @param length 天數
 * @param sessionType 交易時段
 * @returns
 */
export function dualThrustRange(length, sessionType) {
    const dailyHigh = this.dailyHigh(length + 2, sessionType);
    const dailyLow = this.dailyLow(length + 2, sessionType);
    const dailyClose = this.dailyClose(length + 2, sessionType);
    const highArray = [];
    const lowArray = [];
    const closeArray = [];
    for (let i = 0; i < length; i++) {
        highArray.push(dailyHigh[i + 1]);
        lowArray.push(dailyLow[i + 1]);
        closeArray.push(dailyClose[i + 1]);
    }
    const highestHigh = this.maxList(highArray);
    const highestClose = this.maxList(closeArray);
    const lowestLow = this.minList(lowArray);
    const lowestClose = this.minList(closeArray);
    return this.maxList([highestHigh - lowestClose, highestClose - lowestLow]);
}
