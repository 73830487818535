/**
 * 日內的強弱關價
 *
 * @param this
 * @param sessionType 交易時段
 * @returns Number[strongGate, weakGate]
 */
export function strongWeakGate(sessionType) {
    const dayHigh = this.intradayHighest(this.ohlc.highArray, sessionType);
    const dayLow = this.intradayLowest(this.ohlc.lowArray, sessionType);
    const strongPrice = dayLow + (dayHigh - dayLow) * 0.618;
    const weakPrice = dayLow + (dayHigh - dayLow) * 0.382;
    return {
        strongPrice: strongPrice,
        weakPrice: weakPrice,
    };
}
