import dayjs from 'dayjs';
import { SessionType } from '~/modules/SDK/Chart2/SessionType';
/**
 * Determine if the current bar is the first one of the current session.
 *
 * @param this
 * @param session A session object from fr_instrument.getSessions(symbol)
 * @returns
 * @see fr_instrument.tsx
 */
export function isSessionFirstBarV3(session) {
    if (typeof session === 'undefined') {
        console.warn('傳入的session為undefined,  無法判斷isSessionFirstBar');
        return false;
    }
    const resolution = this._context.symbol.period;
    if (resolution === '1D' || resolution === '1W' || resolution === '1M') {
        return false;
    }
    const interval = Number(resolution);
    const timeSeries = this.timeArray;
    const currentBarTime = dayjs(timeSeries.get(0));
    const prevBarTime = currentBarTime.subtract(interval, 'minute');
    const sessionCloseTime = session?.from && dayjs(session.from).set('date', prevBarTime.date());
    const isBaseTimeBeforeStart = sessionCloseTime?.isSame(prevBarTime, 'minute') || sessionCloseTime?.isBefore(prevBarTime);
    const isBaseTimeAfterEnd = sessionCloseTime?.isAfter(currentBarTime);
    // console.log(`
    // result: ${!isBaseTimeBeforeStart && !isBaseTimeAfterEnd}
    // current: ${currentBarTime.format('YYYY-MM-DDTHH:mm:ss')}
    // base: ${sessionCloseTime?.format('YYYY-MM-DDTHH:mm:ss')}
    // prev: ${prevBarTime.format('YYYY-MM-DDTHH:mm:ss')}
    // `)
    return !isBaseTimeBeforeStart && !isBaseTimeAfterEnd;
}
// /**
//  * Determine whether `currentTimestamp` is the first bar of its sessions (comparing with
//  * `previousTimestamp`)
//  *
//  * @param currentTimestamp
//  * @param previousTimestamp
//  * @param resolution
//  * @param sessionType
//  * @returns
//  */
export function isSessionFirstBarV2(currentTimestamp, previousTimestamp, resolution, sessionType) {
    if (resolution === '1D') {
        return true;
    }
    else if (resolution === '1W' || resolution === '1M') {
        return false;
    }
    const currentDatetime = new Date(currentTimestamp);
    let baseTimestamp = 0;
    if (sessionType === SessionType.TWSE) {
        baseTimestamp = currentDatetime.setHours(9, 0, 0, 0);
    }
    else if (sessionType === SessionType.TAIFEX) {
        baseTimestamp = currentDatetime.setHours(15, 0, 0, 0);
    }
    else if (sessionType === SessionType.TAIFEX_AM) {
        baseTimestamp = currentDatetime.setHours(8, 45, 0, 0);
    }
    return previousTimestamp < baseTimestamp && baseTimestamp <= currentTimestamp;
}
/**
 * @param this
 * @param sessionType
 * @returns
 */
export function isSessionFirstBar(sessionType) {
    const resolution = this._context.symbol.period;
    const dateTime = new Date(this.PineJS.Std.time(this._context));
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    if (resolution === '1D') {
        return true;
    }
    else if (resolution === '1W' || resolution === '1M') {
        return false;
    }
    if (sessionType === SessionType.TWSE) {
        return hours === 9 && minutes === 0;
    }
    else if (sessionType === SessionType.TAIFEX) {
        return hours === 15 && minutes === 0;
    }
    else if (sessionType === SessionType.TAIFEX_AM) {
        return hours === 8 && minutes === 45;
    }
    return false;
}
