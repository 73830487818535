/**
 * 取得日線的收盤價
 *
 * @param this
 * @param daysBack 期別
 * @param sessionType 交易時段
 * @returns number[]
 */
export function dailyClose(daysBack, sessionType) {
    const close = this.ohlc.closeArray;
    close.get(4096);
    const prevClose = this._context.new_var();
    prevClose.get(4096);
    if (this.isSessionLastBar(sessionType)) {
        prevClose.set(close.get(0));
    }
    const dailyCloseArray = [];
    for (let i = 0; i < daysBack; i++) {
        if (i === 0) {
            dailyCloseArray.push(close.get(i));
        }
        else {
            dailyCloseArray.push(prevClose.get(i));
        }
    }
    return dailyCloseArray;
}
