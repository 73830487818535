/**
 * 計算ORB通道值
 * @param this
 * @param length
 * @param sessionType
 * @returns PineJS.NewVar<number>
 */
export function openRange(length, sessionType) {
    const dailyOpen = this.dailyOpen(length + 2, sessionType);
    const dailyHigh = this.dailyHigh(length + 2, sessionType);
    const dailyLow = this.dailyLow(length + 2, sessionType);
    const orbList = [];
    for (let i = 0; i < length; i++) {
        orbList.push(this.minList([dailyOpen[i + 1] - dailyLow[i + 1], dailyHigh[i + 1] - dailyOpen[i + 1]]));
    }
    return this.averageList(orbList);
}
