/**
 * Dual Thrust通道指標 Reference: https://zhuanlan.zhihu.com/p/41989276
 *
 * @param this
 * @param length 天數
 * @param fraction 通道大小乘數
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[上通道, 下通道]
 */
export function dualThrust(length, fraction, sessionType) {
    const dailyOpen = this.dailyOpen(length + 2, sessionType);
    const dualThrustRange = this._context.new_var();
    dualThrustRange.set(this.dualThrustRange(length, sessionType));
    dualThrustRange.get(512);
    return {
        top: dailyOpen[0] + fraction * dualThrustRange.get(0),
        bottom: dailyOpen[0] - fraction * dualThrustRange.get(0),
    };
}
