import dayjs from 'dayjs';
import { SessionType } from '~/modules/SDK/Chart2/SessionType';
/**
 * Determine if the current bar is the last one of the current session.
 *
 * @param this
 * @param session A session object from fr_instrument.getSessions(symbol)
 * @returns
 * @see fr_instrument.tsx
 */
export function isSessionLastBarV2(session) {
    if (typeof session === 'undefined') {
        console.warn('傳入的session為undefined,  無法判斷isSessionLastBar');
        return false;
    }
    const resolution = this._context.symbol.period;
    if (resolution === '1D' || resolution === '1W' || resolution === '1M') {
        return false;
    }
    const interval = Number(resolution);
    const timeSeries = this.timeArray;
    const currentBarTime = dayjs(timeSeries.get(0)).add(interval, 'minute');
    const prevBarTime = currentBarTime.subtract(interval, 'minute');
    const sessionCloseTime = session?.to && dayjs(session.to).set('date', prevBarTime.date());
    const isBaseTimeBeforeStart = sessionCloseTime?.isSame(prevBarTime, 'minute') || sessionCloseTime?.isBefore(prevBarTime);
    const isBaseTimeAfterEnd = sessionCloseTime?.isAfter(currentBarTime);
    // console.log(`
    // result: ${!isBaseTimeBeforeStart && !isBaseTimeAfterEnd}
    // current: ${currentBarTime.format('YYYY-MM-DDTHH:mm:ss')}
    // base: ${sessionCloseTime?.format('YYYY-MM-DDTHH:mm:ss')}
    // prev: ${prevBarTime.format('YYYY-MM-DDTHH:mm:ss')}
    // `)
    return !isBaseTimeBeforeStart && !isBaseTimeAfterEnd;
}
// // ================= Old version ==================
export function isSessionLastBar(sessionType) {
    const resolution = this._context.symbol.period;
    const dateTime = new Date(this.PineJS.Std.time(this._context));
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    if (resolution === '1D') {
        return true;
    }
    else if (resolution === '1W' || resolution === '1M') {
        return false;
    }
    if (sessionType === SessionType.TWSE) {
        if (resolution === '1' ||
            resolution === '2' ||
            resolution === '3' ||
            resolution === '5' ||
            resolution === '15' ||
            resolution === '30') {
            return hours === 13 && minutes === 30;
        }
        else if (resolution === '60' || resolution === '240') {
            return hours === 13 && minutes === 0;
        }
    }
    else if (sessionType === SessionType.TAIFEX || sessionType === SessionType.TAIFEX_AM) {
        if (resolution === '1') {
            return hours === 13 && minutes === 44;
        }
        else if (resolution === '2') {
            return hours === 13 && minutes === 43;
        }
        else if (resolution === '3') {
            return hours === 13 && minutes === 42;
        }
        else if (resolution === '5') {
            return hours === 13 && minutes === 40;
        }
        else if (resolution === '15') {
            return hours === 13 && minutes === 30;
        }
        else if (resolution === '30') {
            return hours === 13 && minutes === 15;
        }
        else if (resolution === '60' || resolution === '240') {
            return hours === 12 && minutes === 45;
        }
    }
    return false;
}
